<template>
  <WelcomeView v-if="!user.loggedIn" />
  <UserCollection v-if="user.loggedIn" />
</template>

<script>
import { defineComponent } from "vue";
import { userStore } from "@/stores/user";

// Components
import WelcomeView from "../components/WelcomeView.vue";
import UserCollection from "../components/UserCollectionHome.vue";

export default defineComponent({
  name: "HomeView",
  setup() {
    const user = userStore();
    return { user };
  },

  components: {
    WelcomeView,
    UserCollection,
  },
});
</script>
