<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="3" sm="12">
        <v-card class="mx-auto" max-width="700px">
          <v-card-text>
            <h2 class="mb-4 text-center">Welcome {{ user.image.godName }}</h2>
            <v-img
              v-if="user.image.imageLink"
              class="mx-auto"
              :src="user.image.imageLink"
              max-width="343px"
            />
            <h3 v-if="nftList.length == 0">
              You must purchase a $Spork Star to share your information and play
              with your $Spork.
            </h3>
            <h3 class="my-2 text-center" v-else>
              <v-row>
                <v-col cols="6">
                  $Stars Created:
                  <h1 class="my-2 text-center">
                    {{ forders.length }}
                  </h1>
                </v-col>
                <v-col cols="6">
                  $Stars Connected:
                  <h2 class="my-2 text-center">
                    {{ fconnections.length }}
                  </h2>
                </v-col>
              </v-row>
              Be sure to embody your greatest $Spork Star and enjoy connecting
              with the community.
            </h3>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="elevation-8"
              size="x-large"
              color="yellow"
              block
              variant="flat"
              to="/checkout"
              >Get $Spork Stars</v-btn
            >
          </v-card-actions>
          <v-card-actions>
            <v-btn
              class="elevation-8"
              color="orange"
              block
              variant="flat"
              to="/user"
              >Update User Info</v-btn
            >
          </v-card-actions>
          <v-card-actions>
            <v-btn
              class="elevation-8"
              color="green"
              block
              variant="flat"
              to="/timeline"
              >View $Star Timeline</v-btn
            >
          </v-card-actions>
          <v-card-actions>
            <v-btn
              class="elevation-8"
              color="blue"
              block
              variant="flat"
              to="/connections"
              >View Connections</v-btn
            >
          </v-card-actions>
          <v-card-text> </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="9" sm="12">
        <v-card theme="dark" class="mx-auto mt-4" max-width="512px">
          <v-card-title>
            <h2 class="text-yellow text-center">$Spork Stars</h2>
          </v-card-title>
        </v-card>
        <v-row class="mb-4">
          <template v-for="nft in nftList">
            <v-col
              class="mx-auto"
              cols="12"
              lg="3"
              md="4"
              v-if="
                nft.contract.address ==
                '0x5041114d5c3fc5f18567aa79efd8b609f5c0d343'
              "
              :key="nft.id.tokenId"
            >
              <v-card class="mt-4 nftw-box-gold" height="100%">
                <v-card-title>
                  <h2>
                    {{ nft.title }}
                  </h2>
                </v-card-title>
                <v-card-subtitle>
                  <h2>
                    {{ nft.metadata.attributes.Title }}
                  </h2>
                </v-card-subtitle>
                <v-card-actions>
                  <v-btn
                    @click="
                      save(
                        nft.title,
                        parseInt(nft.id.tokenId),
                        nft.media[0].thumbnail,
                        nft.description,
                        nft.metadata.attributes.Title
                      )
                    "
                    color="pink"
                    class="elevation-8"
                    variant="flat"
                    block
                  >
                    Embody
                  </v-btn>
                </v-card-actions>
                <v-card-text>
                  <v-img
                    v-if="!nft.metadata.image.split('ipfs://')[1]"
                    max-width="340px"
                    class="mx-auto"
                    :src="nft.media[0].thumbnail"
                  >
                  </v-img>

                  <v-img
                    v-if="nft.metadata.image.split('ipfs://')[1]"
                    max-width="512px"
                    class="mx-auto"
                    :src="
                      'https://ipfs.io/ipfs/' +
                      nft.metadata.image.split('ipfs://')[1]
                    "
                  >
                  </v-img>
                </v-card-text>
                <v-card-text>
                  <h3>
                    {{ nft.description }}
                  </h3>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card theme="dark" class="mx-auto my-4" max-width="600px">
          <v-card-title>
            <h2 class="text-orange text-center">TokenPenguin.World</h2>
          </v-card-title>

          <v-card-action class="pa-2">
            <v-spacer></v-spacer>
            <v-btn
              class="my-2"
              href="https://TokenPenguin.World"
              target="_blank"
              color="blue"
              variant="flat"
              block
              >Visit TokenPenguin.World</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-action>
          <v-card-text>
            <h3>
              All $Spork Tokens earned by $SporkStars.com will be converted to
              USDC and donated directly to Penguins International using DePay
              DeFi by purchasing Penguin Chick Project Digital Collectibles.
            </h3>
          </v-card-text>
        </v-card>
        <v-row>
          <template v-for="nft in nftList">
            <!-- Penguin Incubators  -->
            <v-col
              cols="12"
              lg="3"
              md="4"
              v-if="
                nft.contract.address ==
                '0x14146451079d7dd75e8b4b1a3df886f255d65e07'
              "
              :key="nft.id.tokenId"
            >
              <v-card class="mt-4 nftw-box">
                <v-card-title>
                  <h2>
                    {{ nft.title }}
                  </h2>
                </v-card-title>
                <v-card-subtitle>
                  <h3>Of {{ nft.metadata.attributes.Colony }}</h3>
                  <h3>An {{ nft.metadata.attributes.Species }} Penguin</h3>
                </v-card-subtitle>
                <v-card-text>
                  <v-img :src="nft.metadata.image"> </v-img>
                  <h3 class="mt-3">
                    {{ nft.metadata.description.split("This Digital")[0] }}
                  </h3>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="" variant="outlined">
                    <ShareNetwork
                      network="facebook"
                      :url="'https://tokenpenguin.world/pcpImages/' + nft.title"
                      :title="
                        'I supported Penguin Conservation and got ' +
                        nft.title +
                        ' at '
                      "
                      :description="nft.metadata.description"
                      :media="nft.metadata.image"
                      hashtags="penguinconservation"
                      quote="I supported penguin conservation.  You should too! "
                    >
                      <v-icon>mdi-facebook</v-icon>
                    </ShareNetwork>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn variant="outlined">
                    <ShareNetwork
                      network="twitter"
                      :url="'https://tokenpenguin.world/pcpImages/' + nft.title"
                      :title="
                        'I supported penguin conservation and got ' +
                        nft.title +
                        ' at '
                      "
                      :description="nft.metadata.description"
                      :media="nft.metadata.image"
                      hashtags="penguinconservation"
                      twitter-user="PenguinsIntl"
                    >
                      <v-icon>mdi-twitter</v-icon>
                    </ShareNetwork>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn variant="outlined">
                    <ShareNetwork
                      network="pinterest"
                      :url="'https://tokenpenguin.world/pcpImages/' + nft.title"
                      :title="
                        'I supported penguin conservation and got ' +
                        nft.title +
                        ' at '
                      "
                      :description="nft.metadata.description"
                      :media="nft.metadata.image"
                      hashtags="penguinconservation"
                    >
                      <v-icon>mdi-pinterest</v-icon>
                    </ShareNetwork>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn variant="outlined">
                    <ShareNetwork
                      network="reddit"
                      :url="'https://tokenpenguin.world/pcpImages/' + nft.title"
                      :title="
                        'I supported penguin conservation and got ' +
                        nft.title +
                        ' at '
                      "
                      :description="nft.metadata.description"
                      :media="nft.metadata.image"
                      hashtags="penguinconservation"
                    >
                      <v-icon>mdi-reddit</v-icon>
                    </ShareNetwork>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </template>
        </v-row>
        <v-row>
          <template v-for="nft in nftList">
            <v-col
              cols="12"
              lg="6"
              md="6"
              v-if="
                nft.contract.address ==
                '0x6eedf7e748dff4f2a52d31ab1ca4f41e5d2355ff'
              "
              :key="nft.id.tokenId"
            >
              <v-card class="mt-4 nftw-box-green">
                <v-card-title>
                  <h3>
                    {{ nft.title }}
                  </h3>
                </v-card-title>
                <v-card-subtitle>
                  <h3>
                    An {{ nft.metadata.attributes.species }} Penguin Colony
                  </h3>
                </v-card-subtitle>
                <v-card-text>
                  <v-img
                    :src="
                      'https://cloudflare-ipfs.com/ipfs/' +
                      nft.metadata.image.split('ipfs://')[1]
                    "
                  >
                  </v-img>
                </v-card-text>
                <v-card-text>
                  <v-img
                    :src="
                      'https://cloudflare-ipfs.com/ipfs/' +
                      nft.metadata.attributes.incubatorImage.split('ipfs://')[1]
                    "
                    max-width="512px"
                    class="mx-auto"
                  >
                  </v-img>
                </v-card-text>
                <v-card-text>
                  <v-img :src="nft.metadata.image"> </v-img>
                  <h3 class="mt-3">
                    {{ nft.metadata.description.split("This Digital")[0] }}
                  </h3>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    block
                    dark
                    color="green"
                    variant="outlined"
                    :href="
                      'https://www.google.com/maps/place/' +
                      nft.metadata.attributes.colonyLatitude +
                      '+' +
                      nft.metadata.attributes.colonyLongitude
                    "
                    target="_blank"
                    ><v-icon class="mr-2">mdi-map</v-icon> View on Map
                  </v-btn>
                </v-card-actions>
                <v-card-actions>
                  <v-btn
                    block
                    dark
                    color="pink"
                    variant="outlined"
                    :href="nft.metadata.attributes.colonyClimateLinkUrl"
                    target="_blank"
                    ><v-icon class="mr-2">mdi-thermometer</v-icon> Check Their
                    Weather
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </template>
        </v-row>

        <v-card theme="dark" class="mx-auto my-4" max-width="600px">
          <v-card-title>
            <h2 class="text-orange text-center">TokenArt.World</h2>
          </v-card-title>
          <v-card-action class="pa-2">
            <v-spacer></v-spacer>
            <v-btn
              class="my-2"
              href="https://TokenArt.World"
              target="_blank"
              color="blue"
              variant="flat"
              block
              >Visit TokenArt.World</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-action>
        </v-card>

        <!-- Colorado COLORCON -->
        <v-row>
          <template v-for="nft in nftList">
            <v-col
              cols="12"
              lg="3"
              md="4"
              v-if="
                nft.contract.address ==
                '0x49ef18ce580a6b7cbd5818ee922770014dfc4c4c'
              "
              :key="nft.id.tokenId"
            >
              <v-card class="mt-4 nftw-box">
                <v-card-title>
                  <h4>
                    {{ nft.title.split("-")[0] }}
                  </h4>
                  <h3>#{{ nft.title.split("-")[1] }} of 11</h3>
                </v-card-title>
                <v-card-subtitle>
                  <h3>By {{ nft.metadata.attributes.artistName }}</h3>
                </v-card-subtitle>
                <v-card-actions>
                  <v-btn
                    block
                    variant="outlined"
                    :href="nft.metadata.external_url"
                    target="_blank"
                    color="purple"
                    >Visit Artist</v-btn
                  >
                </v-card-actions>
                <v-card-text>
                  <v-img
                    :src="nft.metadata.attributes.inprogressImage"
                    alt="picture"
                  >
                  </v-img>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "@/firebase";

import { userStore } from "@/stores/user";

import router from "@/router";

import axios from "axios";

import { useCollection } from "vuefire";
import { collection } from "firebase/firestore";
import { useFirestore } from "vuefire";

let nftList = ref({});

let user = userStore();

const userRef = doc(db, "users", user.address);

onMounted(async () => {
  const docSnap = await getDoc(userRef);
  if (docSnap.exists()) {
    user.profile = docSnap.data().profile;
    user.image = docSnap.data().image;
    // console.log("Document data:", docSnap.data());
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
  getNftList();
});

const dbf = useFirestore();

// const cuser = useDocument(doc(collection(dbf, "users"), user.address));
const forders = useCollection(
  collection(dbf, "users", user.address, "order-record")
);
const fconnections = useCollection(
  collection(dbf, "users", user.address, "connections")
);

// console.log("connections", fconnections);
// console.log("orders", forders);
// console.log(cuser);

const save = async (godName, tokenId, imageLink, godDescription, godType) => {
  await updateDoc(
    userRef,
    {
      image: {
        godName,
        tokenId,
        imageLink,
        godDescription,
        godType,
      },
    },
    { merge: true }
  );
  router.push("/user");
};

const getNftList = async () => {
  axios
    .get(
      "https://polygon-mainnet.g.alchemy.com/nft/v2/_Rm5u7Qfq2u_D-wsAnmQTUQbIGjPJbgF/getNFTs?owner=" +
        user.address
    )
    .then((response) => (nftList.value = response.data.ownedNfts));
  // .then((response) => console.log(response));
  console.log("NFT List", nftList);
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.nftw-box {
  border-style: solid;
  border-color: black;
  border-width: 5px;
}
.nftw-box-green {
  border-style: solid;
  border-color: green;
  border-width: 5px;
}
.nftw-box-gold {
  border-style: solid;
  border-color: gold;
  border-width: 5px;
}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>