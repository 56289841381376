import {defineStore} from 'pinia'

export const userStore = defineStore({
    id: 'user',
    state: () => ({
        loggedIn: false,
        profile: {
            name: '',
            email: '',
            twitter: '',
            telegram: '',
            project: ''
        },
        image: {
            godName: 'Sporkless',
            imageLink: 'https://firebasestorage.googleapis.com/v0/b/sporkstars-main.appspot.com/o/images%2Fseries-1%2FSporik%20Sporkees.png?alt=media&token=f52ee653-a2c8-41b3-a39a-7233328c7c96',
            greenPlayer: true
        },
        userInfo: null,
        userCred: null,
        address: '',
        maticBalance: 0,
        sporkBalance: 0,

    }),
    persist: {
        enabled: true,
        strategies: [
            {
              key: 'user',
              storage: localStorage,
            },
          ],
    }
})