<template>
  <v-app>
    <v-app-bar app color="black">
      <v-app-bar-nav-icon
        @click="appValues.userDrawer = !appValues.userDrawer"
      ></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <v-btn class="ml-2" to="/" block variant="outlined">
          <v-avatar
            size="42"
            @click="appValues.userDrawer = !appValues.userDrawer"
            rounded="0"
            class="pa-1"
          >
            <v-img
              alt="Spork Stars Logo"
              :placeholder="require('@/assets/spork-logo.png')"
              :src="require('@/assets/spork-logo.png')"
              transition="scale-transition"
            />
          </v-avatar>
          <h2>$Spork Stars</h2>
        </v-btn>
      </div>

      <v-spacer></v-spacer>
      <v-app-bar-nav-icon v-if="user.loggedIn" to="/user">
        <v-avatar size="42" class="mx-1">
          <v-img
            v-if="user.userInfo.uid"
            alt="User Profile Image"
            :src="user.image.imageLink"
          ></v-img>
          <v-img
            v-else
            alt="User Profile Image"
            :src="user.image.imageLink"
          ></v-img>
        </v-avatar>
      </v-app-bar-nav-icon>
      <v-app-bar-nav-icon
        v-else
        @click="appValues.userDrawer = !appValues.userDrawer"
      >
        <v-avatar size="42" class="mx-1">
          <v-icon color="purple" icon="mdi-account"></v-icon>
        </v-avatar>
      </v-app-bar-nav-icon>
    </v-app-bar>

    <!-- User Drawer  -->

    <v-navigation-drawer v-model="appValues.userDrawer" location="left" app>
      <v-card
        class="nftw-box text-center"
        rounded="0"
        aspect-ratio="1"
        height="100%"
      >
        <Web3Auth />
        <v-card-actions v-if="user.loggedIn">
          <v-btn
            class="mt-2 elevation-8"
            to="/checkout"
            block
            color="yellow"
            variant="flat"
            size="large"
          >
            Get $Spork Stars
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="user.loggedIn">
          <v-btn
            class="mt-2 elevation-8"
            to="/user"
            block
            color="orange"
            variant="flat"
          >
            Update User
          </v-btn>
        </v-card-actions>
        <v-card-actions v-if="user.loggedIn">
          <v-btn
            class="mt-2 elevation-8"
            href="https://docs.google.com/presentation/d/1hSLd79xXLwt8c_3PbooLIHNTCpC4vFPj4VyJfvhRzM8/edit?usp=sharing"
            target="_blank"
            block
            color="green"
            variant="flat"
          >
            View NFTW Pitch Deck
          </v-btn>
        </v-card-actions>
        <v-card-text v-if="user.loggedIn">
          <h2 class="mb-2">Share Code</h2>
          <qr-code
            class="mx-auto"
            :text="'https://SporkStars.com/uid/' + user.address"
            size="216"
            error-level="Q"
          ></qr-code>
          <v-btn class="mt-2" variant="outlined" @click="copyUrl"
            >Copy to Clipboard</v-btn
          >
        </v-card-text>

        <v-card class="nftw-box ma-1 ma-4 pa-1" light v-if="!user.loggedIn">
          <p class="text-center">This project was <br />created by NFTW</p>
          <a href="https://newfrontierworks.com" target="_blank">
            <v-img
              class="mx-auto"
              src="https://cloudflare-ipfs.com/ipfs/Qmd2vqZgoP2h7J3EnxSa7oUVfDk9RfLd6TSBo1nhmV9yLQ"
              max-width="188px"
            ></v-img>
          </a>
          <h2>Contact Us to put your Ad here.</h2>
        </v-card>
      </v-card>
      <!-- {{ user }} -->
    </v-navigation-drawer>
    <v-main>
      <router-view />
    </v-main>
    <v-footer app height="44px">
      <h6>&copy; NFTW 2023</h6>
      <a class="ml-2" href="/privacy-policy"><h6>Privacy Policy</h6></a>

      <v-spacer></v-spacer>
      <h5>$Spork Balance: {{ user.sporkBalance }}</h5>
      <v-spacer></v-spacer>
      <v-btn
        color="orange"
        icon="mdi-qrcode-scan"
        size="large"
        class="mb-12"
        to="/qrscanner"
      ></v-btn>
    </v-footer>
  </v-app>
</template>

<script>
import { userStore } from "@/stores/user";
import { appValuesStore } from "@/stores/appValues";

import QrCode from "vue-qrcode-component";

import Web3Auth from "./components/auth/Web3Auth.vue";

export default {
  name: "App",
  setup() {
    const user = userStore();
    const appValues = appValuesStore();
    const copyUrl = async () => {
      if (!navigator.clipboard) {
        // Clipboard API not available
        return;
      }
      try {
        await navigator.clipboard.writeText(
          "https://sporkstars.com/uid/" + user.address
        );
        console.log("Copied to clipboard");
      } catch (err) {
        console.error("Failed to copy!", err);
      }
    };

    return { copyUrl, appValues, user };
  },

  components: { Web3Auth, QrCode },
  data: () => ({}),
};
</script>

<style>
main {
  background-repeat: repeat;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='1000' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23FAFAFA' stroke-width='1.8'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23F6F6F6'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}
.headlink {
  text-decoration: none !important;
  color: white !important;
}
h1,
h2,
h3 {
  font-family: Righteous, Haettenschweiler, Franklin Gothic Bold, Charcoal,
    Helvetica Inserat, Bitstream Vera Sans Bold, Arial Black, sans serif;
  text-transform: none !important;
}
.v-card__text,
.v-card__title {
  word-break: normal !important;
}

.nftw-box {
  border-style: solid !important;
  border-color: black !important;
  border-width: 5px !important;
}
</style>