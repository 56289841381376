import {defineStore} from 'pinia'

export const appValuesStore = defineStore({
    id: 'appValues',
    state: () => ({
       userDrawer: false

    }),
    persist: {
        enabled: true
    }
})