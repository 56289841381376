<template>
  <v-container fluid>
    <v-btn
      v-if="!user.loggedIn"
      class="elevation-8"
      @click="login"
      style="cursor: pointer"
      variant="flat"
      color="purple"
      block
    >
      <v-icon class="mr-2">mdi-login</v-icon> Login
      <v-icon class="ml-2">mdi-account</v-icon>
    </v-btn>
    <div v-if="!user.loggedIn">
      <v-card variant="flat">
        <v-card-actions>
          <v-icon>mdi-google</v-icon>
          <v-spacer></v-spacer>
          <v-icon>mdi-apple</v-icon>
          <v-spacer></v-spacer>
          <v-icon>mdi-facebook</v-icon>
          <v-spacer></v-spacer>
          <v-icon>mdi-twitter</v-icon>
          <v-spacer></v-spacer>
          <v-icon>mdi-email</v-icon>
          <v-spacer></v-spacer>
          <v-icon>mdi-ethereum</v-icon>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </div>
    <div>
      <v-dialog v-model="loading" :scrim="false" persistent width="auto">
        <v-card color="primary">
          <v-card-text class="text-center">
            Please Be Patient <br />
            Login In Progress
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="loadingExternal"
        :scrim="false"
        persistent
        width="auto"
      >
        <v-card color="primary">
          <v-card-text class="text-center">
            Wallet Connect Requires<br />a Connect Step and a Sign Step<br />

            If Stuck Check Your Wallet For Signatures <br />
            or Reload the Page and Try Again

            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="web3mount" :scrim="false" persistent width="auto">
        <v-card color="primary">
          <v-card-text>
            Web 3 Warming Up
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <v-btn
      v-if="user.loggedIn"
      class="elevation-8"
      @click="logout"
      style="cursor: pointer"
      variant="flat"
      color="purple"
      block
    >
      <v-icon class="mr-2">mdi-login</v-icon> Logout
      <v-icon class="ml-2">mdi-account</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { ref, reactive, onMounted, defineComponent } from "vue";
import { Web3Auth } from "@web3auth/web3auth";
import { CHAIN_NAMESPACES } from "@web3auth/base";
import { getFunctions, httpsCallable } from "firebase/functions";
import { signInWithCustomToken, signOut } from "firebase/auth";
import Web3 from "web3";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "@/firebase";

import { createAlchemyWeb3 } from "@alch/alchemy-web3";

import router from "@/router";

import { userStore } from "@/stores/user";

import { appValuesStore } from "@/stores/appValues";

// Incase of secp256k1 curve, get the app_pub_key
import { getPublicCompressed } from "@toruslabs/eccrypto";

export default defineComponent({
  name: "Web3Auth",
  setup() {
    const appValues = appValuesStore();
    const loading = ref(false);
    const loadingExternal = ref(false);
    const web3mount = ref(false);
    const loginButtonStatus = ref("");
    const connecting = ref(false);
    let provider = reactive({});
    let web3 = reactive({});
    let userAddress = ref("");
    let idToken = reactive({});
    let userInfo = reactive({});

    let loggedin = ref(false);

    const user = userStore();

    const clientId = process.env.VUE_APP_WEB3AUTH; // get from https://dashboard.web3auth.io

    const web3auth = new Web3Auth({
      clientId,
      uiConfig: {
        appLogo:
          "https://cloudflare-ipfs.com/ipfs/Qmd2vqZgoP2h7J3EnxSa7oUVfDk9RfLd6TSBo1nhmV9yLQ",
        theme: "dark",
        loginMethodsOrder: ["google", "apple", "facebook", "twitter"],
      },
      chainConfig: {
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: "0x89",
        rpcTarget: "https://polygon-rpc.com", // This is the mainnet RPC we have added, please pass on your own endpoint while creating an app
      },
    });

    onMounted(async () => {
      try {
        await web3auth.initModal();
      } catch (error) {
        console.log("error", error);
      }
      verifyToken();
    });

    const getMaticBalance = async () => {
      user.maticBalance = web3.utils.fromWei(
        await web3.eth.getBalance(userAddress.value) // Balance is in wei
      );
    };
    const getSporkBalance = async () => {
      // Initialize an alchemy-web3 instance:
      const web3 = createAlchemyWeb3(
        `https://polygon-mainnet.g.alchemy.com/v2/FvNCMZi1eJZSOU6E5YPiHGFZiWiEXOlE`
      );

      //The below token contract address corresponds to USDT
      const tokenContractAddresses = [
        "0x9CA6a77C8B38159fd2dA9Bd25bc3E259C33F5E39",
      ];

      const data = await web3.alchemy.getTokenBalances(
        userAddress.value,
        tokenContractAddresses
      );
      // console.log("Token balance for Address");
      // console.log(data.tokenBalances[0].tokenBalance);
      user.sporkBalance =
        data.tokenBalances[0].tokenBalance / 1000000000000000000;
    };

    const login = async () => {
      logout();
      if (!web3auth) {
        await web3auth.initModal();
        console.log("web3auth now initialized", web3auth);
        return;
      }
      appValues.userDrawer = false;

      provider = await web3auth.connect();
      // console.log("current provider", provider);
      web3 = new Web3(provider);
      userAddress.value = (await web3.eth.getAccounts())[0];
      user.address = userAddress.value;
      // console.log("address", userAddress.value);
      verifyToken();
    };

    const verifyToken = async () => {
      idToken = await web3auth.authenticateUser();
      userInfo = await web3auth.getUserInfo();

      user.userInfo = userInfo;

      userAddress.value = (await web3.eth.getAccounts())[0];
      // console.log("In verifyToken");
      // console.log("Token: ", idToken);
      // console.log("userInfo: ", userInfo);
      // console.log("web3auth.provider: ", provider);
      // console.log("address: ", userAddress.value);
      // Determine if we have a social login or an external wallet
      let address;
      let app_pub_key;
      let authFunction;
      if (userInfo.name !== undefined && userInfo.name !== "") {
        // Social Login
        const app_scoped_privkey = await web3auth.provider.request({
          method: "eth_private_key", // use "private_key" for other non-evm chains
        });
        app_pub_key = getPublicCompressed(
          Buffer.from(app_scoped_privkey.padStart(64, "0"), "hex")
        ).toString("hex");
        // console.log("App Pub Key", app_pub_key);

        address = userAddress.value;

        authFunction = "auth-torusWalletWeb3Auth";
        console.log("Using social login for auth");
        loading.value = true;
      } else {
        // External wallet
        address = (await web3.eth.getAccounts())[0];
        app_pub_key = address;
        authFunction = "auth-externalWalletWeb3Auth";
        console.log("Using external wallet for auth");
        loadingExternal.value = true;
      }

      // console.log("balance", balance);
      // console.log("app_pub_key ", app_pub_key);
      // console.log("address ", address);
      // console.log("authFunction ", authFunction);

      // Verify idToken with Firebase Function
      const functions = getFunctions();
      const addMessage = httpsCallable(functions, authFunction);
      addMessage({ idToken, app_pub_key: app_pub_key, address: address })
        .then((result) => {
          // Read result of the Cloud Function.

          const customToken = result.data.customToken;
          // Sign in using the custom token
          // console.log("Read customToken from function call: ", customToken);
          // console.log("Auth: ", auth);

          signInWithCustomToken(auth, customToken)
            .then((userCredential) => {
              // Signed in
              const userCred = userCredential.user;
              // console.log("Signed in as user ", userCred);
              user.userCred = userCred;
              user.loggedIn = true;
              loading.value = false;
              loadingExternal.value = false;
              getMaticBalance();
              getUserFirestore();
              getSporkBalance();
              router.push("/user");
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log(
                "Error signing in with token ",
                errorCode,
                errorMessage
              );
            });
        })
        .catch((error) => {
          // Getting the Error details.
          const code = error.code;
          const message = error.message;
          console.log("Error getting auth token: ", code, message);
        });
    };

    const getUserFirestore = async () => {
      const docRef = doc(db, "users", userAddress.value);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        user.profile = docSnap.data().profile;
        user.image = docSnap.data().image;
        // console.log("Document data:", docSnap.data());
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    };

    const logout = async () => {
      user.loggedIn = false;
      provider = null;
      loggedin.value = false;
      user.address = "";
      user.userInfo = {};
      user.maticBalance = 0;
      user.sporkBalance = 0;
      user.profile = {
        name: "@",
        email: "@",
        twitter: "@",
        telegram: "@",
      };
      user.image = {
        godName: "Sporkless",
        imageLink:
          "https://firebasestorage.googleapis.com/v0/b/sporkstars-main.appspot.com/o/images%2FSporkStars-Logo.jpg?alt=media&token=cd65beda-eda6-472f-aa06-23441cdff0f0",
        godType: "None",
      };
      appValues.userDrawer = false;
      await web3auth.logout();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
        })
        .catch((error) => {
          console.log(error);
          // An error happened.
        });

      router.push("/");
    };

    return {
      appValues,
      user,
      loading,
      loadingExternal,
      web3mount,
      loginButtonStatus,
      connecting,
      provider,
      web3auth,
      loggedin,
      userInfo,
      idToken,
      userAddress,
      login,
      verifyToken,
      logout,
      getSporkBalance,
      getMaticBalance,
      getUserFirestore,
    };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a,
a:-webkit-any-link {
  text-decoration: none;
}
.card:hover,
.card:focus,
.card:active {
  cursor: pointer;
  background-color: #f1f1f1;
}
</style>