import { createApp } from 'vue'
import { VueFire, VueFireAuth } from 'vuefire'
import App from './App.vue'
import router from './router'
import vuetify from '@/plugins/vuetify'
import { createPinia } from 'pinia'
import piniaPersist from 'pinia-plugin-persist'
import { loadFonts } from './plugins/webfontloader'
import { firebaseApp  } from './firebase'

import VueSocialSharing from 'vue-social-sharing'


const pinia = createPinia()
pinia.use(piniaPersist)

loadFonts()

createApp(App)
.use(VueFire, {
    // imported above but could also just be created here
    firebaseApp,
    modules: [
      // we will see other modules later on
      VueFireAuth(),
    ],
  })
.use(router)
.use(vuetify)
.use(pinia)
.use(VueSocialSharing)
.mount('#app')


