<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" md="6" sm="12">
        <h1>$Spork Stars</h1>

        <h3>
          A Stylish and $Sporkish Way to Share your contact info and play with
          your $Spork at ETHDenver
        </h3>
        <v-carousel height="400" show-arrows="hover" hide-delimiters cycle>
          <v-carousel-item
            v-for="(item, i) in sporkstars"
            :key="i"
            :src="item.image"
          >
          </v-carousel-item>
        </v-carousel>
        <h2 class="mb-2" dark>
          The $Spork Gods
          <br />
          are currently available.
        </h2>
        <h3 class="mt-4">
          The $Spork Beings will be releasing at
          <a href="https://ETHDenver.com" target="_blank"> ETHDenver 2023. </a>
        </h3>
      </v-col>
      <v-col cols="12" md="6" sm="12">
        <v-card class="mx-auto pa-4 h-100" variant="outlined" dark>
          <v-card-title>
            <h1>How It Works:</h1>
          </v-card-title>
          <v-card-text>
            <ol class="text-h6 pa-lg-8 pa-sm-2">
              <li class="my-2 text-left">Login to Create an Account*</li>
              <v-btn
                @click="appValues.userDrawer = true"
                color="pink"
                variant="flat"
                block
                >Get Started</v-btn
              >

              <li class="my-2 text-left">
                Purchase a $Spork Stars Digital Collectible for $2 USD with
                debit / credit or
                <a href="https://www.sporkdao.org/" target="_blank">
                  2 $Spork Tokens
                </a>
                directly or with Depay DeFi using an external wallet to become a
                member.
              </li>
              <li class="my-2 text-left">
                Embody your $Spork God or $Spork Being and receive
                <a href="https://www.sporkdao.org/" target="_blank">
                  1 $Spork Token </a
                >at each $Snapshot for each $Spork Star you have created.
              </li>

              <li class="my-2 text-left">
                Add your contact info to join the $Spork Stars timeline.
              </li>
              <li class="my-2 text-left">
                Use the QR Code Generator and Scanner to build Connections and
                receive
                <a href="https://www.sporkdao.org/" target="_blank">
                  1 $Spork Token </a
                >at each $Snapshot for each valid Connection you have built with
                other $Spork Stars .
                <ul class="ml-4">
                  <router-link to="/terms-and-conditions">
                    <h4 class="text-center">View Terms and Conditions</h4>
                  </router-link>
                </ul>
              </li>
              <!-- <li class="my-2 text-left">
                Share your $Spork Stars on social media to get people pumped
                about using their $Spork to build even more Connections.
              </li> -->
              <!-- <li class="text-left">
                Earn 1
                <a href="https://www.sporkdao.org/" target="_blank">
                  $Spork Token</a
                >
                for Each Referal.
              </li> -->
            </ol>
            <h3 class="mt-4">
              *A valid Polygon address will be created for you unless you log in
              with an external wallet.
            </h3>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref } from "vue";

import { appValuesStore } from "@/stores/appValues";
// import { userStore } from "@/stores/user";

// const user = userStore();

const appValues = appValuesStore();

const sporkstars = ref([
  {
    name: "",
    image:
      "https://firebasestorage.googleapis.com/v0/b/sporkstars-main.appspot.com/o/images%2FSporkStars-Logo.jpg?alt=media&token=cd65beda-eda6-472f-aa06-23441cdff0f0",
  },
  {
    name: "Sporkaia",
    image:
      "https://firebasestorage.googleapis.com/v0/b/sporkstars-main.appspot.com/o/images%2Fspork-gods%2FSporkaia.png?alt=media&token=5a3375cf-6070-4f6b-b82f-963a5fd7d24e",
  },
  {
    name: "Sporkal",
    image:
      "https://firebasestorage.googleapis.com/v0/b/sporkstars-main.appspot.com/o/images%2Fspork-gods%2FSporkal.png?alt=media&token=e61e2343-1654-4da1-849d-7a76536a70a2",
  },
  {
    name: "Sporkamos",
    image:
      "https://firebasestorage.googleapis.com/v0/b/sporkstars-main.appspot.com/o/images%2Fspork-gods%2FSporkamos.png?alt=media&token=346d3720-43aa-4ec1-ac43-e13d882a58d5",
  },
  {
    name: "Sporkandra",
    image:
      "https://firebasestorage.googleapis.com/v0/b/sporkstars-main.appspot.com/o/images%2Fspork-gods%2FSporkandra.png?alt=media&token=8e9a44f0-0310-4e0c-86b4-cdd0accc065f",
  },
  {
    name: "Sporkath",
    image:
      "https://firebasestorage.googleapis.com/v0/b/sporkstars-main.appspot.com/o/images%2Fspork-gods%2FSporkath.png?alt=media&token=045b6f23-1356-47fa-a2ba-64aea2c68285",
  },
]);
</script>

<style>
main {
  background-repeat: repeat;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1000' height='1000' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23FAFAFA' stroke-width='1.8'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23F6F6F6'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
}
.headlink {
  text-decoration: none !important;
  color: white !important;
}
h1,
h2,
h3 {
  font-family: Righteous, Haettenschweiler, Franklin Gothic Bold, Charcoal,
    Helvetica Inserat, Bitstream Vera Sans Bold, Arial Black, sans serif;
  text-transform: none !important;
}
.v-card__text,
.v-card__title {
  word-break: normal !important;
}

.nftw-box {
  border-style: solid !important;
  border-color: black !important;
  border-width: 5px !important;
}
</style>